import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"

import { Link, graphql } from "gatsby"

import Logo from "../assets/svgs/logo.svg";
import back_fullsize_mp4 from "../assets/back_01_fullsize.mp4";
import back_fullsize_webm from "../assets/back_01_fullsize.webm";

const Header = () => {
  return (
    <header className="main_header">
      <div className="header_inner_wrapper">
        <div className="top_nav">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

const JobRow = ({ jobs }) => {
  return (
    <div className="row">
      {jobs.map(job =>
        <div className="job_opening col_3">
          <Link to={job.slug}>
            <h5 className="job_title">
              {job.name}
            </h5>
          </Link>
        </div>
      )}
    </div>
  )
}

const JobWrapper = ({ jobs_arr, is_active }) => {

  return (
    <div className={is_active ? "job_openings_wrapper opened" : "job_openings_wrapper"}>
      <div className="job_openings_inner_wrapper row">
        <div className="col_12">
          <div className="job_openings">
            {jobs_arr.map(job_row => <JobRow jobs={job_row} />)}
          </div>
        </div>
      </div>
    </div>
  )
}



const JobTitle = ({ job_title }) => {
  const [active, setActive] = useState(false)

  const toggle = () => {
    console.log('toggle')
    setActive(!active)
  }

  const chunkArr = (arr, chunkSize) => {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
    return R;
  }


  const jobs_arr = chunkArr(job_title.positions, 4)

  return (
    <li className={active ? "job_tag opened_title" : "job_tag"} data-job_cat="software">
      <a href="#" onClick={toggle}>{job_title.title}</a>
      <JobWrapper is_active={active} jobs_arr={jobs_arr} />
    </li>
  )
}



const Content = () => {
  const data = {
    "jobs": [
      {
        "id": 1,
        "title": "Software Development",
        "positions": [
          {
            "id": 1,
            "name": "Junior Software Developer",
            "slug": "/junior-software-developer/",
            "form_url": "https://g1nlenb45us.typeform.com/to/Wr7z5EEX"
          },
          {
            "id": 2,
            "name": "Senior Software Developer",
            "slug": "/senior-software-developer/",
            "form_url": "https://g1nlenb45us.typeform.com/to/e5ZfX1WG"
          },
          {
            "id": 3,
            "name": "Junior Mobile Developer",
            "slug": "/junior-mobile-developer/",
            "form_url": "https://g1nlenb45us.typeform.com/to/o3QGxVjf"
          },
        ]
      },
      /*{
        "id": 2,
        "title": "Art Design",
        "positions": [
      
        ]
      }*/
    ]
  }
  return (
    <div id="content" data-content-context="home">
      <div id="page_background_videos">
        <video
          className="background_video"
          poster="poster.png"
          autoPlay
          loop
          muted
          playsInline
        >
          <source className="fullsize" src={back_fullsize_webm} type="video/webm" />
          <source className="fullsize" src={back_fullsize_mp4} type="video/mp4" />
        </video>
      </div>

      <section id="jobs" className="job_skills full_bleed">
        <div className="section_inner_wrapper">
          <div className="search_form_wrapper">
            <div className="row search_header">
              <div className="col_6 job_skills_title">
                <h3>
                  <a href="#">
                    Yenilikçi ve gerçekçi projeler geliştiriyoruz. Bize katıl.
                  </a>
                </h3>
                <p style={{ color: "#fff" }}>
                  Hem bugün için, hem yarın için çözümler üretecek,
                  arkadaşlarını yükselten, öğrenmeye açık ve meraklı insanlar
                  arıyoruz. Hayatlara dokunan teknolojiler geliştirmek
                  istiyorsan, seni bekliyoruz.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col_12">
              <ul className="job_tags">
                {data.jobs.map(job => <JobTitle job_title={job} />)}
                <li className="job_tag empty_answer">
                  <Link to="/general-application">
                    General Application
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// markup
const IndexPage = () => {
  useEffect(() => {
    document.body.className = 'black';
    return () => document.body.className = null;
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Takyon Jobs</title>
        <link rel="canonical" href="https://jobs.takyon.ai/" />
      </Helmet>
      <Header />
      <Content />
    </>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`


export default IndexPage;
